/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Duplicata} from '../../models/duplicata'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";
import {DuplicataSCH, getDuplicataSCH} from "../../search/DuplicataSCH";

const url = '/duplicata'

const state = {
  all: [],
  allList: [],
  duplicata: new Duplicata(),
  filtro: new Filtro()
}

const getters = {
  listaAllDuplicatas: (state) => {
    return state.allList
  },

  listaDuplicatas: (state) => {
    return state.all
  },
  pegaDuplicata: (state) => {
    return state.duplicata
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descr','numero','comprador'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {duplicata_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('duplicata_id', duplicata_id, '=')
      const list = (await pegaDados(url+'/get_duplicata', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_DUPLICATA', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },
  async getDuplicatasByVenda({commit}, {venda_id, onSucesso,onErro}) {
    try {
      console.log('getDuplicatasByVenda');
      var filtro = getDuplicataSCH('venda_id', venda_id, '=')
      const list = (await pegaDados(url+'/get_duplicata', filtro)).data
      onSucesso(list.data)
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', DuplicataSCH(perPage))
  },

  async setAllListDuplicatas({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
    },
  async setAllDuplicatas({commit},{onSucesso, onErro}) {
    try {
      // console.log(JSON.parse(JSON.stringify(state.filtro.filters)));
      const list = (await pegaDados(url + '/get_view', state.filtro)).data
      console.log('SET_DUPLICATAS:', list.data)
      commit('SET_DUPLICATAS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async getRecebidas({commit},{filtro,onSucesso, onErro}) {
    try {
      // console.log(JSON.parse(JSON.stringify(state.filtro.filters)));
      const list = (await pegaDados(url + '/get_duplicatas_recebidas', filtro)).data
      onSucesso(list)
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarDuplicata({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.duplicata, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  //  async deleteDuplicatas({commit}, {venda,onSucesso,onErro}) {
  //   try {
  //     let res = await processar(url + '/commit_del_dup', venda, 'remover')
  //
  //     this.dispatch('geral/setMsgSuccess', res.data.message)
  //     onSucesso()
  //   } catch (error) {
  //     onErro()
  //     console.log('error: ',error)
  //     this.dispatch('geral/setObjMsgError', error.response.data)
  //   }
  // },
  async processarDuplicataReceber({commit}, {duplicata,onSucesso,onErro}) {
    try {
      let res = await processar(url + '/commit_receber', duplicata, 'gravar')

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarEstorno({commit}, {duplicata,onSucesso,onErro}) {
    try {
      let res = await processar(url + '/estorno', duplicata, 'gravar')
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setDuplicata({commit}, duplicata) {
    commit('SET_DUPLICATA', duplicata)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_DUPLICATAS(state, duplicatas) {
    state.all = duplicatas
  },
  SET_DUPLICATA(state, duplicata) {
    state.duplicata = duplicata
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
